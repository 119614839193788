<template>
    <form :action="route('login')" class="text-center w-full max-w-[440px]" method="post">
        <input type="hidden" name="_token" :value="csrfToken" />

        <input type="hidden" name="PreviousUrl" :value="evpLoginForm.previousUrl">
        
        <div class="mb-4 font-light">{{ $t('global.LoginInfo') }}</div>

        <div class="flex flex-col lg:flex-row items-stretch w-full space-y-1.5 lg:space-y-0 lg:space-x-2">
            <a :href="route('verify-eid', {ActionReason: 'Login'})" title="eID">
                <div class="w-full h-12 lg:h-full button-secondary shadow-none pl-1 lg:pl-4 lg:pr-3">
                    <e-paraksts-svg class="h-6 lg:h-7" />
                </div>
            </a>
            <div class="grow grid grid-cols-1 lg:grid-cols-2 gap-1.5">
                <a :href="route('verify-swedbank', {ActionReason: 'Login'})" title="Swedbank">
                    <div class="w-full h-12 button-secondary shadow-none">
                        <img class="h-6 md:h-[1.35rem]" src="@/images/auth/auth.m.swedbank.48px.png" alt="Swedbank" />
                    </div>
                </a>
                <a :href="route('verify-citadele', {ActionReason: 'Login'})" title="Citadele">
                    <div class="w-full h-12 button-secondary shadow-none pb-0.5">
                        <svg
                            class="h-7 md:h-6"
                            xmlns="http://www.w3.org/2000/svg"
                            style="fill: #e3002c"
                            viewBox="0 0 85 38.79"
                        >
                            <path class="a" d="M32.6,46.47c0-3.21,1.54-5,3.88-5A5.31,5.31,0,0,1,39,42.2c.32.15.59.09,1-.91l.31-.68c.3-.83.32-1.18,0-1.35a8.79,8.79,0,0,0-4.2-1c-4.11,0-7.24,3.29-7.24,8.25S31.9,54.75,36,54.75a9.12,9.12,0,0,0,4.25-1c.36-.17.34-.53,0-1.34L40,51.63c-.43-1-.69-1.07-1-.91a5.31,5.31,0,0,1-2.51.71c-2.34,0-3.88-1.73-3.88-5" transform="translate(-28.77 -15.98)" />
                            <path class="a" d="M78.13,46.25a2.26,2.26,0,0,0-1.63-.72c-1.35,0-2,1.19-2,3.36a3.73,3.73,0,0,0,.64,2.46,1.72,1.72,0,0,0,1.36.56,1.7,1.7,0,0,0,1.34-.44c.2-.23.28-.57.28-1.35ZM81.71,54c0,.45-.17.57-1.39.57H80.2c-1.29,0-1.47-.07-1.57-.39a6.17,6.17,0,0,1-.16-.65,3.32,3.32,0,0,1-2.76,1.25c-3,0-4.87-2.26-4.87-5.87,0-3.92,2-6.24,5-6.24a3.6,3.6,0,0,1,2.24.75V38.31c0-.34,0-.48,1.73-.48H80c1.57,0,1.74.14,1.74.48Z" transform="translate(-28.77 -15.98)" />
                            <path class="a" d="M100,54.07c0,.33-.16.48-1.74.48h-.11c-1.7,0-1.74-.15-1.74-.48V38.31c0-.34,0-.48,1.74-.48h.11c1.58,0,1.74.14,1.74.48Z" transform="translate(-28.77 -15.98)" />
                            <path class="a" d="M91,47.64c-.06-1.2-.54-2.25-1.73-2.25-1,0-1.77.77-1.91,2.25Zm-3.6,2.24A2.26,2.26,0,0,0,89.82,52a7.68,7.68,0,0,0,3.25-.76c.25-.1.46,0,.72.81l.19.44c.26.84.24,1,0,1.18a8.75,8.75,0,0,1-4.44,1.09c-3.56,0-5.8-2.18-5.8-5.93s2.06-6.18,5.58-6.18,5.27,2.5,5.27,6c0,1.15-.1,1.27-.5,1.27Z" transform="translate(-28.77 -15.98)" />
                            <path class="a" d="M109.13,47.64c-.07-1.2-.56-2.25-1.73-2.25s-1.78.77-1.91,2.25Zm-3.6,2.24A2.24,2.24,0,0,0,108,52a7.75,7.75,0,0,0,3.25-.76c.24-.1.46,0,.72.81l.17.44c.27.84.25,1,0,1.18a8.83,8.83,0,0,1-4.46,1.09c-3.55,0-5.78-2.18-5.78-5.93s2-6.18,5.56-6.18,5.28,2.5,5.28,6c0,1.15-.11,1.27-.51,1.27Z" transform="translate(-28.77 -15.98)" />
                            <path class="a" d="M68.74,54c0,.45-.16.57-1.39.57h-.16c-1.28,0-1.46-.07-1.57-.4-.05-.17-.12-.44-.16-.64a3.3,3.3,0,0,1-2.75,1.25c-2.92,0-4.78-2.21-4.78-5.83,0-4,2.31-6.28,5-6.28a3.38,3.38,0,0,1,2.6,1.13l.05-.5c0-.24.09-.4,1.41-.4h.15c1.36,0,1.56.1,1.56.46Zm-3.61-7.64a2,2,0,0,0-1.56-.78c-1.34,0-2,1.18-2,3.29,0,1.79.48,3.12,1.94,3.12a1.6,1.6,0,0,0,1.23-.43c.26-.27.37-.65.37-1.5Z" transform="translate(-28.77 -15.98)" />
                            <path class="a" d="M52,39.49c1.35,0,1.42,0,1.42.45v2.94H55.7c.32,0,.42,0,.42,1.16v.16c0,1.11-.1,1.17-.42,1.17H53.38v5.27c0,.9.16,1.36,1,1.36a3.56,3.56,0,0,0,1.15-.21c.38-.15.52,0,.78,1l.06.22c.28,1,.2,1.1-.16,1.27a6.58,6.58,0,0,1-2.59.52c-2.87,0-3.67-1.43-3.67-4V45.37H48.57c-.33,0-.41-.06-.41-1.17V44c0-1.11.08-1.16.41-1.16h1.52c.15-1,.45-2.27.61-2.94.11-.37.17-.45,1.26-.45" transform="translate(-28.77 -15.98)" />
                            <path class="a" d="M44.38,37.65a1.88,1.88,0,1,1-2,1.89,1.91,1.91,0,0,1,2-1.89m.1,5.23c1.56,0,1.71.14,1.71.49v10.7c0,.36-.15.48-1.71.48h-.1c-1.73,0-1.77-.12-1.77-.48V43.37c0-.35,0-.49,1.77-.49Z" transform="translate(-28.77 -15.98)" />
                            <rect
                                class="a"
                                x="0.22"
                                width="19.03"
                                height="4.82"
                                rx="0.62"
                            />
                            <rect
                                class="a"
                                x="0.22"
                                y="8.59"
                                width="19.03"
                                height="4.82"
                                rx="0.62"
                            />
                        </svg>
                    </div>
                </a>
                <a :href="route('verify-luminor', {ActionReason: 'Login'})" title="Luminor">
                    <div class="w-full h-12 button-secondary shadow-none pb-0.5">
                        <svg
                            class="h-7 md:h-6"
                            xmlns="http://www.w3.org/2000/svg"
                            style="fill: #471335"
                            viewBox="0 60 1000 250"
                        >
                            <path
                                d="M481.36,155.93c-28.26,0-36.57,16.83-36.65,17l-.63,1.33-.81-1.23c-7.6-11.49-18.53-17.09-36.76-17.09-28.26,0-36.58,16.83-36.65,17l-.63,1.3-9.42-15.37H340V271h30.77V215.41c0-21.71,7.43-31.82,23.41-31.82,14.11,0,20.69,8.4,20.69,26.44V271H445.6v-56.3c0-21.21,7.29-31.08,22.92-31.08,9.09,0,21.18,2.77,21.18,26.68V271h30.76V201.22C520.46,169.91,508.23,155.93,481.36,155.93ZM290.1,214.46c0,14.61-4.53,24-11.61,28.52-3.48,2.19-9,
                        3.3-14.25,3.3-17.73,0-22.13-10.12-22.13-26.69V158.9H211.35v69.75c0,31.31,15.56,45.26,43.24,45.26h0c29.37,0,37-18.28,37-18.28L301,271h19.82V158.9H290.1Zm476.58-58.53c-18.11,0-33.59,6-44.74,17.46-10.46,10.71-16.22,25.46-16.22,41.54s5.76,30.82,16.22,41.53c11.15,11.43,26.63,17.46,44.74,17.46,36,0,61.22-24.25,61.22-59S802.72,155.93,766.68,155.93Zm-.12,91.85c-17.47,0-30.15-13.82-30.15-32.85,0-18.73,13-32.86,30.15-32.86,17.47,0,30.64,
                        14.13,30.64,32.86C797.2,234,784.32,247.78,766.56,247.78ZM123,113.8h-32V271H200.72V239H123ZM536,271h32V158.86H536Zm333.47-96.75L860,158.86H840.21V271H871V228.69c0-14.61,2.72-23.71,11.43-31.55,11.15-10,26.55-9.83,26.55-9.83V155.93C876,155.93,869.45,174.23,869.45,174.23ZM529.19,113.8,552,136.56l22.76-22.76L552,91ZM651.67,156v0c-29.36,0-37,18.28-37,18.28l-9.43-15.37H585.41V271h30.76V215.41c0-14.61,4.53-24,11.6-28.51,3.47-2.2,9-3.31,
                        14.26-3.31,17.72,0,22.12,10.12,22.12,26.68V271h30.76V201.22C694.91,169.91,679.36,156,651.67,156Z"
                            />
                        </svg>
                    </div>
                </a>
                <a :href="route('verify-seb', {ActionReason: 'Login'})" title="SEB">
                    <div class="w-full h-12 button-secondary shadow-none pb-0.5">
                        <svg
                            xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 0 800 325"
                            class="h-7 md:h-6"
                        >
                            <path 
                                style="fill: #1d1d1b;" 
                                d="M227.53,118.61l-40.77,30.44c-19.25-16.56-44-19.81-57.75-19.81s-28.29,4-30.53,13.9c-2.16,9.64,6.79,17.13,22.19,21.31,17.56,4.74,33.55,8.92,58.17,16,36.63,10.46,61.67,26.29,61.67,63,0,50.65-44.95,76.9-105.18,76.9S34.08,289,21.82,274.32L64.91,242.1c30.28,26.39,51.33,30.11,68.92,30.11,20.19,0,39.07-4.34,41.49-15.68,2-9.17-5.63-16.47-22.83-20.46-19.46-4.49-35.88-8.11-54.2-12.93-49.67-13-63.68-41.91-63.68-67.84,0-35.72,33.25-72.41,
                            94.06-72.41,62.35,0,98.86,35.72,98.86,35.72M487.39,86.46H305.65V313.61H490.72V261.24H369.47V224.65H453V174.53H369.47V138.36H487.39ZM258.61,366h14.12V34H258.61Zm256,0H528.7V34H514.59Zm46.95-52.43h146c39,0,70.66-23.53,70.66-61.2,0-29.39-19.23-48.89-46.2-57.47a14.71,14.71,0,0,0-2.61-.33,16.42,16.42,0,0,0,2.61-.45c15.45-6.12,32.56-22.4,32.56-50.12,0-35.15-31.1-57.58-69.5-57.58H561.54ZM625.68,133.7h51c11.74,0,21.24,9.26,21.24,
                            19.92s-9.5,20-21.24,20h-51Zm62.37,129.42H625.68V221.7h62.37c11,0,20,8.94,20,20.71S699.08,263.12,688.05,263.12Z" 
                                transform="translate(-21.82 -33.96)"
                            />
                        </svg>
                    </div>
                </a>
            </div>
        </div>

        <div class="py-3 font-light">
            {{ $t('global.OrUsing') }}

            <div class="py-0.5">
                ({{ $t('global.ForRegisteredUsers') }})
            </div>
        </div>

        <div class="space-y-1">
            <input
                type="text"
                name="Login"
                :value="evpLoginForm.emailOldInput"
                :placeholder="$t('global.LoginPlaceholder')"
                :aria-label="$t('global.LoginPlaceholder')"
                class="text-input h-11 w-full"
            />
            <input
                type="password"
                name="Password"
                :placeholder="$t('global.PasswordPlaceholder')"
                :aria-label="$t('global.PasswordPlaceholder')"
                class="text-input h-11 w-full"
            />
        </div>

        <button type="submit" class="button-primary w-full h-10 mt-2.5">
            {{ $t('global.Login') }}
        </button>

        <div class="text-center py-2">
            <a :href="route('reset-password')" class="link-primary">
                {{ $t('global.ForgotPassword') }}
            </a>
        </div>
    </form>
</template>
<script setup>
    import { route } from 'ziggy'
    import EParakstsSvg from '@/components/EParakstsSvg.vue'

    const evpLoginForm = window.evpLoginForm
    const csrfToken = window.evp.csrfToken

</script>